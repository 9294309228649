.error{
  color:red;
}

/*
.MuiDataGrid-root{
  border: none!Important;
}
.MuiDataGrid-columnHeaders{
  font-size: 11px!Important;
  font-weight: 600!Important;
  background-color: #c6c9ca !Important;
  height: 40px!Important;
  min-height: 40px!Important;
}
.MuiDataGrid-columnHeaderTitle{
  font-size: 11px!Important;
  font-weight: 600!Important;
  color: #232f66 !Important;

}

.MuiDataGrid-overlay{
  top: -13px!Important;
}

.MuiDataGrid-cell{
  font-size: 11px!Important;
  font-weight: 500!Important;
}
.MuiDataGrid-row{
  min-height: 30px!Important;
}
.MuiDataGrid-cell{
  min-height: 30px!Important;
}
.MuiDataGrid-cellCheckbox {
  min-height: 30px!Important;
}
.MuiDataGrid-cell--textCenter{
  min-height: 30px!Important;
}
.MuiDataGrid-columnSeparator{
  display: none!Important;
}

.MuiDataGrid-footerContainer{
  margin-bottom: 25px;
  height: 25px;
  min-height: 40px!Important;
}



.MuiDataGrid-virtualScroller{
  margin-top: 42px!Important;
}
.MuiDataGrid-virtualScrollerContent{
  height: auto!Important;
}
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within{
  outline: none!Important;
}*/
